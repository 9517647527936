<template>
  <div id="single-blog">
    <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home">
            <router-link to="/">Home </router-link>
          </li>
          <li class="item blog">
            <strong>{{ blogcontents.title }}</strong>
          </li>
          <!-- <li class="item blog_post">
            
          </li> -->
        </ul>
      </div>
    </div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12">
          <h2 class="page-title">
            <span class="base" data-ui-id="page-title-wrapper">{{
              blogcontents.title
            }}</span>
          </h2>
          <div class="post-view">
            <div class="post-holder post-holder-5">
              <div class="post-content">
                <div class="post-description clearfix">
                  <div class="post-ftimg-hld">
                    <img :src="blogcontents.blog_image" />
                  </div>
                  <div class="post-text-hld" v-html="description">
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="post-bottom">
                <!-- <div class="post-nextprev-hld clearfix">
                                    <a class="nextprev-link prev-link" href=""
                                        title="Read Moving from Ticket System to Forum">
                                        ← Previous </a>
                                    <a class="nextprev-link next-link" href=""
                                        title="Read Customer Support Notice for Holiday">
                                        Next → </a>
                                </div> -->
                <!-- <div class="block comments">
                                    <div id="fb-root" class=" fb_reset">
                                        <div style="position: absolute; top: -10000px; width: 0px; height: 0px;">
                                            <div></div>
                                        </div>
                                    </div>
                                    <div class="post-comments clear">
                                        <div class="fb-comments fb_iframe_widget fb_iframe_widget_fluid_desktop"
                                            data-href="" data-width="100%" data-numposts="4" style="width: 100%;"
                                            fb-xfbml-state="rendered"
                                            fb-iframe-plugin-query="app_id=&amp;container_width=1148&amp;height=100&amp;href=http%3A%2F%2Fmagento2.magentech.com%2Fthemes%2Fsm_shopee%2Fdefault%2Fblog%2Fpost%2F8-ideas-to-get-your-site-ready&amp;locale=en_US&amp;numposts=4&amp;sdk=joey&amp;version=v2.5&amp;width=">
                                            <span style="vertical-align: bottom; width: 100%; height: 231px;"><iframe
                                                    name="f2831ee28668ee"
                                                    data-testid="fb:comments Facebook Social Plugin"
                                                    title="fb:comments Facebook Social Plugin" allowtransparency="true"
                                                    allowfullscreen="true" scrolling="no" allow="encrypted-media"
                                                    style="border: medium none; visibility: visible; width: 100%; height: 231px;"
                                                    src="https://www.facebook.com/v2.5/plugins/comments.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2278e39e830a0c%26domain%3Dmagento2.magentech.com%26origin%3Dhttp%253A%252F%252Fmagento2.magentech.com%252Ff31588dd7009a56%26relation%3Dparent.parent&amp;container_width=1148&amp;height=100&amp;href=http%3A%2F%2Fmagento2.magentech.com%2Fthemes%2Fsm_shopee%2Fdefault%2Fblog%2Fpost%2F8-ideas-to-get-your-site-ready&amp;locale=en_US&amp;numposts=4&amp;sdk=joey&amp;version=v2.5&amp;width="
                                                    class="" width="1000px" height="100px"
                                                    frameborder="0"></iframe></span>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleBlog",
  data() {
    return {
      description: "",
      blogcontents: [],
    };
  },
  created() {
    this.getBlogsDetails();
  },
  methods: {
    getBlogsDetails() {
      var segment_str = window.location.pathname;
      var segment_array = segment_str.split("/");
      var last_segment = segment_array.pop();
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/blog/getBlogDetails";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          slug: last_segment,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.blogcontents != null) {
            this.description = data.blogcontents.description;
            this.blogcontents = data.blogcontents;
          }
          this.loader = false;
        });
    },
  },
};
</script>